<template>
  <div class="topic">
    <section class="header-section" :class="{'hide': listHeaderHide}">
			<section class="search-section">
				<div class="container">
					<div class="search-bar">
						<span><i class="fas fa-search"></i></span>
						<input type="text" placeholder="關鍵字搜尋" v-model="searchText" />
					</div>
				</div>
			</section>
			<section class="category-section">
				<div class="container">
          <flickity class="category-carousel" ref="categoryFlickity" :options="categoryFlickityOpt">
            <div class="category-cell" v-for="(item, index) in categories" :key="index">
              <a href="#" @click.prevent="categoryCellClicked(index)" class="category-pill" :class="{'active': index === categoryIndex}">
                {{item}}
              </a>
            </div>
          </flickity>
				</div>
			</section>
		</section>

    <section class="list-section">
			<section class="banner-section" v-show="categoryIndex === 0">
        <flickity class="banner-carousel" v-if="init" ref="bannerFlickity" :options="bannerFlickityOpt">
          <div class="banner-cell" v-for="(item, index) in bannerPosts" :key="index">
            <router-link :to="{name: 'TopicContent', params: {id: item.id}}" class="banner-card">
              <div class="post-img">
                <img :src="item.image.url" />
              </div>
              <p>{{ item.title }}</p>
              <span>{{ postDateStr(item.published) }}</span>
            </router-link>
          </div>
        </flickity>
			</section>
			<div class="container">
				<p class="text-center" v-if="filteredList.length === 0">沒有符合的文章</p>
				<ul>
					<li v-for="(item) in filteredList" :key="item.id">
            <router-link :to="{name: 'TopicContent', params: {id: item.id}}" class="list-card">
              <div class="img">
								<div>
									<img :src="item.image.url" />
								</div>
							</div>

							<div class="content">
								<p class="title">{{ item.title }}</p>
                <span>{{ postDateStr(item.published) }}</span>
							</div>
            </router-link>
					</li>
				</ul>
			</div>

			<!-- <div style="height: 200vh;">
			</div> -->
		</section>

  </div>
</template>

<script>
// @ is an alias to /src
import Flickity from 'vue-flickity';
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'Topic',
  data() {
    return {
      init: false,
      searchText: '',
			bannerFlickity: null,
			categories: ['總覽', '主題精選', '人氣', '穿搭', '收納', '循環經濟'],
			categoryIndex: 0,
			categoryFlickity: null,

			postList: [],
      readBusyName: 'TOPICREADING',
			postShow: false,
			postDetail: null,

      listScrollTop: 0,
      listLastScroll: 0,
      listHeaderHide: false,
      listHeaderHideThreshold: 0,

      categoryFlickityOpt: {
        wrapAround: false,
        cellAlign: 'center',
        contain: true,
        pageDots: false,
        prevNextButtons: false,
        initialIndex: 0,
      },
      bannerFlickityOpt: {
        wrapAround: false,
        cellAlign: 'center',
        contain: true,
        pageDots: true,
        prevNextButtons: false,
        initialIndex: 0,
      }
    };
  },
  props: {
    scroll: {
      type: Number,
      required: true,
    }
  },
  components: {
    Flickity,
  },
  computed: {
		filteredList() {
			const _this = this;
			const typeRes = this.postList.filter(function(item) {
        if (_this.categoryIndex === 0) {
          return true;
        }
        if (typeof item.labels === 'undefined') {
          return false;
        }
				const lInd = item.labels.indexOf(_this.categories[_this.categoryIndex]);
				if (lInd !== -1) {
					return true;
				}
				return false;
			});
			const textSearchRes = typeRes.filter(function(item) {
				return (_this.searchText.trim().length === 0 || item.title.indexOf(_this.searchText.trim()) !== -1);
			});
			return textSearchRes;
		},
		bannerPosts() {
			if (this.postList.length > 6) {
				return this.postList.slice(0,6);
			}
			return this.postList;
		}
	},
  watch: {
    scroll(val) {
      this.listScrollTop = val;

      const GAP = 50;
      const TOPGAP = 50;
      const hiding = this.listHeaderHide;
      const direction = (val < this.listLastScroll)?-1:(val > this.listLastScroll)?1:0;
      // console.log(direction);
      if(!hiding){
        if(direction == -1){
          this.listHeaderHideThreshold = this.listLastScroll + GAP;
        }else if(direction == 1 && val > TOPGAP){
          if(val > this.listHeaderHideThreshold){
            this.listHeaderHide = true;
            this.listHeaderHideThreshold = this.listLastScroll - GAP;
          }
        }
      }else{
        if(direction == 1){
          this.listHeaderHideThreshold = this.listLastScroll - GAP;
        }else if(direction == -1){
          if(val < this.listHeaderHideThreshold || val < TOPGAP){
            this.listHeaderHide = false;
            this.listHeaderHideThreshold = this.listLastScroll + GAP;
          }
        }
      }
      this.listLastScroll = val;
    }
  },
  mounted() {
    this.appendComponentBusy(this.readBusyName);
		this.$store.dispatch('api/readPostListPromise').then((posts) => {
			this.postList.splice(0, this.postList.length);
			posts.map((item) => {
				this.postList.push(item);
			});
		}).catch((e) => {
      this.appendErrorMsg(e);
		}).then(() => {
      this.clearComponentBusy(this.readBusyName);
      this.init = true;
		});
	},
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'updateCloth']),
    categoryCellClicked(index) {
      this.categoryIndex = index;
			// this.categoryFlickity.select( index );
		},
    postDateStr(t) {
      return moment(t).format('YYYY.MM.DD');
    },
  }
}
</script>


<style scoped>
  .topic {
    width: 100%;
    /* height: 100vh; */
    /* display: flex; */
    /* flex-direction: column; */
    /* padding-top: 90px; */
  }

  .search-section {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }

  .search-bar {
    display: flex;
    align-items: center;
    border-radius: .5rem;
    background-color: #f9f9f9;
    overflow: hidden;
    color: #c4c4c4;
    padding: .5rem 1rem;
    font-size: .75rem;
  }

  .search-bar>span {
    display: block;
    flex: 0 0 auto;
    padding-right: .5rem;
  }

  .search-bar>input {
    display: block;
    flex: 1 1;
    background-color: transparent;
    border: none;
  }

  .search-bar>input:focus {
    outline: none;
  }

  .search-bar>input::placeholder { /* CSS 3 標準 */
    color: #c4c4c4;
  }

  .search-bar>input::-webkit-input-placeholder { /* Chrome, Safari */
    color: #c4c4c4;
  }

  .search-bar>input:-ms-input-placeholder { /* IE 10+ */
    color: #c4c4c4;
  }

  .search-bar>input::-moz-placeholder { /* Firefox 19+ */
    color: #c4c4c4;
    opacity: 1;
  }

  /* banner */
  .banner-section {
    padding: 1rem 0 2.5rem;

  }

  .banner-carousel {
    position: relative;
    width: 100%;
  }

  .banner-cell {
    width: 60%;
    position: relative;
    margin-right: 10px;
  }

  .banner-card {
    display: block;
    position: relative;
  }

  .post-img {
    position: relative;
    padding-top: 53.4%;
  }

  .post-img>img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .banner-card>p {
    text-align: right;
    margin: 0;
    font-size: .8rem;
    color: #4D4D4D;
  }

  .banner-card>span {
    display: block;
    text-align: right;
    margin: 0;
    font-size: .6rem;
    color: #C4C4C4;
  }

  /* category */
  .category-section {
    padding-bottom: .5rem;
  }

  .category-carousel {

  }

  .category-cell {

  }

  .category-pill {
    padding: .1rem .5rem;
    border-radius: 999px;
    margin-right: 1rem;
    color: #d4d4d4;
    font-size: 14px;
  }

  .category-pill:hover {
    text-decoration: none;
  }

  .category-pill.active {
    color: #fff;
    background-color: #4d4d4d;
  }

  .one-page {
    width: 100%;
    /* height: 100vh; */
    /* display: flex; */
    /* flex-direction: column; */
    padding-top: 90px;
  }

  .search-section, .category-section, .banner-section {
    /* flex: 0 0 auto; */
  }

  .header-section {
    position: sticky;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform .8s ease;
    background-color: #fff;
  }

  .header-section.hide {
    transform: translate(0, -100%);
  }

  .list-section {
    /* flex: 1 1; */
    overflow-y: auto;
    padding: 0 0 1rem 0;
  }

  .list-section ul {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -4px;
    margin-right: -4px;
    padding: 0;
    list-style: none;
  }

  .list-section li {
    flex: 0 0 50%;
    padding: 4px;
    margin: 0;
    list-style: none;
  }

  .list-card {
    /* display: flex; */
    margin-bottom: 1rem;
    /* border-radius: .5rem; */
    /* background-color: #fff; */
    /* box-shadow: 0px 4px 4px 0px #0000002e; */
    overflow: hidden;
  }

  .list-card:hover {
    text-decoration: none;
  }

  .list-card .content {
    position: relative;
    margin-top: .2rem;
  }

  .list-card .content .title {
    margin: 0;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #4d4d4d;
  }

  .list-card .content>span {
    display: block;
    text-align: right;
    margin: 0;
    font-size: .6rem;
    color: #C4C4C4;
  }


  .list-card .img {
    position: relative;
  }

  .list-card .img>div {
    width: 100%;
    padding-top: 53.4%;
  }

  .list-card .img>div>img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }

  /* post content */
  .post-enter-active, .post-leave-active {
    transition: opacity .4s ease, transform .4s ease;
  }

  .post-enter, .post-leave-to {
    opacity: 0;
    /* transform: scale(.8); */
    transform: translate(20%, 0);
  }

  .post-enter-to, .post-leave {
    opacity: 1;
    /* transform: scale(1); */
    transform: translate(0, 0);
  }

</style>
